<template>
  <div class="f-home-box">
    <div class="header-box">
      <div style="width: 100%;" v-if="scrollTopVal > 0"></div>
      <div :class="['header', scrollTopVal > 0 ? 'scrollTopFixed' : '']">
        <div class="header-left">
          <div class="menu-style">
            <a-menu v-model="currents" mode="horizontal">
              <a-menu-item key="topBox" @click="navbar({name:'首页'})">首页</a-menu-item>
              <a-menu-item key="mainBusiness" @click="navbar({name:'主营业务'})">主营业务</a-menu-item>
              <a-menu-item key="advantage" @click="navbar({name:'平台优势'})">平台优势</a-menu-item>
              <a-menu-item key="products" @click="navbar({name:'金彩贷'})">金彩贷</a-menu-item>
              <a-menu-item key="aboutUs" @click="navbar({name:'关于我们'})">关于我们</a-menu-item>
              <!--              <a-menu-item key="news" @click="navbar({name:'新闻动态'})">新闻动态</a-menu-item>-->
              <a-menu-item key="foot" @click="navbar({name:'联系我们'})">联系我们</a-menu-item>
            </a-menu>
          </div>
        </div>
      </div>
    </div>

    <div class="topBox" ref="topBox">
      <div class="topBanner">
        <img class="jcdImg" src="https://oss.itcait.com/jincdev/webImg/jcd.png" alt="">
        <div>
          <div class="titleFont">It's a wonderful life.</div>
          <div class="titleFont">Borrow money if you want.</div>
          <div class="position" @click="navbar({name:'关于我们'})">
            <div class="aboutus">about us</div>
          </div>
        </div>
      </div>
    </div>

    <div class="classification">
      <div class="item">
        <div class="itemBox">
          <img class="itemImg" src="https://oss.itcait.com/jincdev/webImg/gzt.png" alt="">
          <div class="itemName">主营业务</div>
        </div>
        <div class="itemBox">
          <img class="itemImg" src="https://oss.itcait.com/jincdev/webImg/jp.png" alt="">
          <div class="itemName">平台优势</div>
        </div>
        <div class="itemBox">
          <img class="itemImg" src="https://oss.itcait.com/jincdev/webImg/ggcp.png" alt="">
          <div class="itemName">产品展示</div>
        </div>
        <div class="itemBox">
          <img class="itemImg" src="https://oss.itcait.com/jincdev/webImg/zs.png" alt="">
          <div class="itemName">资质荣誉</div>
        </div>
        <div class="itemBox">
          <img class="itemImg" src="https://oss.itcait.com/jincdev/webImg/xwgg.png" alt="">
          <div class="itemName" ref="mainBusiness">新闻公告</div>
        </div>
      </div>
    </div>

    <!--    主营业务-->
    <div class="mainBusiness">
      <div style="width: 1200px;margin: 0 auto">
        <div class="top">
          <div class="shu"></div>
          <div class="topTitle">主营业务</div>
        </div>
        <div class="mainBusinessContent">
          <div>
            <div class="consumerDebt">消费贷</div>
            <div class="three">三步到账就这么简单</div>
            <img class="sortImg" src="https://oss.itcait.com/jincdev/webImg/123.png" alt="">
            <div class="itemSHBox">
              <div class="itemSH" v-for="(item,index) in SHList" :key="index">
                <img class="itemSHImg" :src="item.image" alt="">
                <div class="itemSHName">{{ item.name }}</div>
              </div>
            </div>
            <div class="bottom">
              <div class="shu1"></div>
              <div class="bottom-right">
                <div class="bottomFont">申请资料简单、审核快速，额度高，最快2小时放款</div>
                <div class="bottomFont">贷款期限及利率:期限灵活，利息为单利6%的年化利率</div>
                <div class="bottomComputation irr">年化利率的计算规则:</div>
                <div class="bottomComputation">1.到期还本付息年利率为:(利息总额/(T*本金))*100%，T为总年数。</div>
                <div class="bottomComputation">2.等额本息年利率为:本金=∑第一期还款额(1+IRR/n)12，n为年内期数，T为总数。
                </div>
              </div>
            </div>
          </div>
          <div class="qrBox">
            <img class="qrImg" :src="require('@/assets/index/qr.png')" alt="">
            <div class="rightBottomBox">
              <div class="rightBottom">
                <div class="through">通过率高</div>
                <img class="tglImg" src="https://oss.itcait.com/jincdev/webImg/tgl.png" alt="">
              </div>
              <div class="rightBottom">
                <div class="through">利率低</div>
                <img class="tglImg" src="https://oss.itcait.com/jincdev/webImg/lld.png" alt="">
              </div>
              <div class="rightBottom">
                <div class="through">极速放款</div>
                <img class="jsImg" ref="advantage" src="https://oss.itcait.com/jincdev/webImg/js.png" alt="">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--    平台优势-->
    <div>
      <div class="advantage">
        <div class="advantageBox">
          <div class="advantageTop">
            <div class="shu"></div>
            <div class="topTitle">平台优势</div>
          </div>
          <div class="advantageItem">
            <div class="advantageItemBox">
              <img class="itemADImg" src="https://oss.itcait.com/jincdev/webImg/mkd.png" alt="">
              <div class="itemADName">门槛低</div>
              <div class="itemADContent">满18—59周岁均有机会申请</div>
            </div>
            <div class="advantageItemBox">
              <img class="itemADImg" src="https://oss.itcait.com/jincdev/webImg/edg.png" alt="">
              <div class="itemADName">额度高</div>
              <div class="itemADContent">最高借款额度达20万</div>
            </div>
            <div class="advantageItemBox">
              <img class="itemADImg" src="https://oss.itcait.com/jincdev/webImg/dzk.png" alt="">
              <div class="itemADName">到账快</div>
              <div class="itemADContent">最快5分钟到账</div>
            </div>
            <div class="advantageItemBox">
              <img class="itemADImg" src="https://oss.itcait.com/jincdev/webImg/glh.png" alt="">
              <div class="itemADName">更灵活</div>
              <div class="itemADContent" ref="products">最高可分24期</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--    产品展示-->
    <div class="products">
      <div class="productsBox">
        <div class="productsTop">
          <div class="shu"></div>
          <div class="topTitle">产品展示</div>
        </div>
        <div class="productsContent">
          <img class="jk1Img" src="https://oss.itcait.com/jincdev/webImg/jk.png" alt="">
          <img class="sxImg" src="https://oss.itcait.com/jincdev/webImg/sx.png" alt="">
          <img class="jkImg" src="https://oss.itcait.com/jincdev/webImg/jk1.png" alt="">
          <div class="productsContent-left">
            <img class="jcdImg" src="https://oss.itcait.com/jincdev/webImg/jcd1.png" alt="">
            <img class="xdImg" src="https://oss.itcait.com/jincdev/webImg/xd.png" alt="">
            <div class="jcdFont">金彩贷赋能金融机构提质增效，助推普惠金融高质量发展，让更多人享受到安全便捷的金融科技服务。
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--    关于我们-->
    <div class="aboutUs" ref="aboutUs">
      <div class="aboutUsTop">
        <div class="shu"></div>
        <div class="topTitle">关于我们</div>
      </div>
      <div class="recommendation">
        <div class="yuan"></div>
        <div class="recommendationName">品牌介绍</div>
      </div>
      <div class="recommendationOne">
        彩通小贷由赣州市彩通网络小额贷款有限公司作为单一发起人，在赣州市龙南县发起设立，注册资本50000万元，于2017年9月获得江西省小额贷款公司经营许可证。
      </div>
      <div class="recommendationOne recommendationTwo">
        彩通网络小贷以“贴近社区的精准定位、直接造福广大江西人民群众；辅助江西中小微经济发展、促进群众就业、提高财政税收；以社区+网络小贷创新模式，为江西省树立全国金融创新的先进标杆”优势，打破区域限制，从实地促进金融资本与江西省实体经济的高度融合，以全方位的金融服务助力江西经济社会健康快速发展。
      </div>
    </div>

    <!--    荣誉资质-->
    <div class="honor">
      <div class="honorBox">
        <div class="recommendations">
          <div class="yuan"></div>
          <div class="recommendationName">荣誉资质</div>
        </div>
        <div class="xkzBox">
          <img class="xkzImg" src="https://oss.itcait.com/jincdev/webImg/xkz.png" alt="">
        </div>
      </div>
    </div>

    <!--    底部导航-->
    <div class="foot" ref="foot">
      <div class="footBox">
        <div>
          <div class="title">快捷导航</div>
          <div class="itemTitName" v-for="(item,index) in footList" :key="index" @click="navbar(item,index)">
            <div>{{ item.name }}</div>
          </div>
        </div>
        <div class="shu3"></div>
        <div class="phoneBox">
          <img class="phoneImg" src="https://oss.itcait.com/jincdev/webImg/phone.png" alt="">
          <div>
            <!--            <div class="phoneContent">-->
            <!--              <div>公司主机</div>-->
            <!--              <div class="number">400-101-5870</div>-->
            <!--            </div>-->
            <div class="phoneContent kfContent">
              <div>客服热线</div>
              <div class="number">400-101-5870</div>
            </div>
          </div>
        </div>
        <div class="shu3"></div>
        <div class="wx-qr">
          <img class="qrcode" :src="require('@/assets/index/AGDHHb02SU.png')" alt="">
          <div class="wx">微信扫一扫下载App</div>
        </div>
      </div>
      <div class="jtBox">
        <img class="jtImg" src="https://oss.itcait.com/jincdev/webImg/jt.png" alt="">
      </div>
    </div>
    <div class="address">
      <div class="bottom-address">
        <img class="addressImg" src="https://oss.itcait.com/jincdev/webImg/address.png" alt="">
        <div class="addressName">江西省赣州市龙南市中央城商务大夏B-1703A</div>
      </div>
      <div class="bottom-name">赣州市彩通网络小额贷款有限公司 <a style="color: #8a8a8c"
                                                                 href="https://beian.miit.gov.cn">赣ICP备17015241号-3</a>
      </div>
    </div>

    <!--    右侧浮动-->
    <!--    <div class="float" v-if="showTop" @mouseenter="mouseenter" @mouseleave="mouseleave">-->
    <!--      <img class="navbarImg"-->
    <!--           :src="!showRightTab ? 'https://oss.itcait.com/jincdev/webImg/navbar.png' : 'https://oss.itcait.com/jincdev/webImg/selNavbar.png'"-->
    <!--           alt="">-->
    <!--      <div v-if="showItem" class="itemNavName" v-for="(item,index) in navbarList" :key="index"-->
    <!--           @click="navbar(item,index)">-->
    <!--        <div>{{ item.name }}</div>-->
    <!--      </div>-->
    <!--    </div>-->
  </div>
</template>

<script>

export default {
  data() {
    return {
      scrollTopVal: 0,
      showItem: true,
      showTop: false,
      showRightTab: false,
      currents: ['topBox'],
      navbarList: [
        {
          name: '首页'
        },
        {
          name: '关于我们'
        },
        {
          name: '主营业务'
        },
        {
          name: '金彩贷'
        },
        {
          name: '新闻动态'
        },
        {
          name: '联系我们'
        }
      ],
      footList: [
        {
          name: '首页'
        },
        {
          name: '关于我们'
        },
        {
          name: '主营业务'
        },
        {
          name: '平台优势'
        },
        {
          name: '金彩贷'
        },
        // {
        //   name: '新闻动态'
        // },
        {
          name: '联系我们'
        }
      ],
      advantageList: [
        {
          img: 'https://oss.itcait.com/jincdev/webImg/mkd.png',
          name: '门槛低',
          content: '满18—59周岁均有机会申请'
        },
        {
          img: 'https://oss.itcait.com/jincdev/webImg/edg.png',
          name: '额度高',
          content: '最高借款额度达20万'
        },
        {
          img: 'https://oss.itcait.com/jincdev/webImg/dzk.png',
          name: '到账快',
          content: '最快5分钟到账'
        },
        {
          img: 'https://oss.itcait.com/jincdev/webImg/glh.png',
          name: '更灵活',
          content: '最高可分24期'
        }
      ],
      SHList: [
        {
          image: 'https://oss.itcait.com/jincdev/webImg/xxsh.png',
          name: '线上审核'
        },
        {
          image: 'https://oss.itcait.com/jincdev/webImg/rrsh.png',
          name: '人工审核'
        },
        {
          image: 'https://oss.itcait.com/jincdev/webImg/jsfk.png',
          name: '极速放款'
        }
      ], //审核

      classificationList: [
        {
          img: 'https://oss.itcait.com/jincdev/webImg/gzt.png',
          name: '主营业务'
        },
        {
          img: 'https://oss.itcait.com/jincdev/webImg/jp.png',
          name: '平台优势'
        },
        {
          img: 'https://oss.itcait.com/jincdev/webImg/ggcp.png',
          name: '产品展示'
        },
        {
          img: 'https://oss.itcait.com/jincdev/webImg/zs.png',
          name: '资质荣誉'
        },
        {
          img: 'https://oss.itcait.com/jincdev/webImg/xwgg.png',
          name: '新闻公告'
        }
      ],
      currentResolution: '',
    };
  },

  mounted() {
    this.setCurrentResolution();
    window.addEventListener('resize', this.setCurrentResolution);
    this.$nextTick(() => {
      // 开启滚动条监听
      window.addEventListener("scroll", this.scrollTop, true);
    });
  },

  beforeDestroy() {
    window.removeEventListener('scroll', this.scrollTop, true);
    window.removeEventListener('resize', this.setCurrentResolution);
  },

  methods: {
    setCurrentResolution() {
      const width = window.innerWidth;
      const height = window.innerHeight
      if (width <= 1920 && height <= 1080) {
        this.currentResolution = 'mobile';
      }
      if (width >= 1920 && height > 1080) {
        this.currentResolution = 'tablet';
      }
    },

    // 鼠标移入
    mouseenter() {
      this.showItem = true
      this.showRightTab = true
    },

    // 鼠标移出
    mouseleave() {
      this.showItem = false
      this.showRightTab = false
    },

    // 控制右侧导航栏的显示隐藏
    scrollTop() {
      this.showItem = true
      let elVal = document.documentElement.scrollTop || document.body.scrollTop || window.pageYOffset;
      let top = Math.floor(elVal)
      this.scrollTopVal = top;
      console.log(elVal, 'elVal')
      if (this.currentResolution == 'tablet') {
        if (elVal < 1190) {
          this.currents = ['topBox']
        } else if (elVal >= 1200 && elVal < 1950) {
          // this.showTop = true
          this.currents = ['mainBusiness']
        } else if (elVal >= 1990 && elVal < 2490) {
          // this.showTop = true
          this.currents = ['advantage']
        } else if (elVal >= 2500 && elVal < 3340) {
          this.currents = ['products']
          // this.showTop = false
          // this.showRightTab = false
        } else if (elVal >= 3350 && elVal < 3740) {
          this.currents = ['aboutUs']
        } else if (elVal >= 3750) {
          this.currents = ['foot']
        }
      } else if (this.currentResolution == 'mobile') {
        if (elVal < 900) {
          this.currents = ['topBox']
        } else if (elVal >= 900 && elVal < 1670) {
          // this.showTop = true
          this.currents = ['mainBusiness']
        } else if (elVal >= 1670 && elVal < 2140) {
          // this.showTop = true
          this.currents = ['advantage']
        } else if (elVal >= 2150 && elVal < 3040) {
          this.currents = ['products']
          // this.showTop = false
          // this.showRightTab = false
        } else if (elVal >= 3050 && elVal < 3775) {
          this.currents = ['aboutUs']
        } else if (elVal >= 3785) {
          this.currents = ['foot']
        }
      }
    },

    navbar(tab, index) {
      // 根据点击的选项卡值来滚动到相应的卡片位置
      switch (tab.name) {
        case '首页':
          this.scrollToCard('topBox');
          // this.showTop = false
          // this.currents = ['topBox']
          break;
        case '关于我们':
          this.scrollToCard('aboutUs');
          // this.showTop = true
          // setTimeout(() => {
          //   this.showItem = false
          //   this.showRightTab = false
          // }, 3000)
          break;
        case '主营业务':
          this.scrollToCard('mainBusiness');
          // this.showTop = true
          // setTimeout(() => {
          //   this.showItem = false
          //   this.showRightTab = false
          // }, 3000)
          break;
        case '平台优势':
          this.scrollToCard('advantage');
          // this.showTop = true
          // setTimeout(() => {
          //   this.showItem = false
          //   this.showRightTab = false
          // }, 3000)
          break;
        case '金彩贷':
          this.scrollToCard('products');
          // this.showTop = true
          // setTimeout(() => {
          //   this.showItem = false
          //   this.showRightTab = false
          // }, 3000)
          break;
        case '新闻动态':
          // this.scrollToCard('resourceQuery');
          break;
        case '联系我们':
          this.scrollToCard('foot');
          // this.showTop = true
          // setTimeout(() => {
          //   this.showItem = false
          //   this.showRightTab = false
          // }, 3000)
          break;
          //你可以在这里添加新的想定位到达的部分
      }
    },

    scrollToCard(refName) {
      // 使用 Vue 的 $refs 来获取卡片元素，并滚动到其位置
      const cardElement = this.$refs[refName];
      if (cardElement) {
        // 使用原生的 scrollIntoView 方法来滚动到元素位置
        cardElement.scrollIntoView({behavior: 'smooth', block: 'start'});
      }
    },
  },
};
</script>

<style lang="less" scoped>
.f-home-box {
  width: 100%;

  .header-box {
    width: 100%;
    position: absolute;
    z-index: 999;

    .header {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 8px;
      color: #fff;
      transition: all 1s;

      .logoBox {
        display: flex;
        align-items: center;

        .logo-img {
          width: 60px;
          height: 60px;
        }

        .logo-name {
          font-size: 16px;
          font-weight: 500;
          color: #FFFFFF;
          margin-left: 12px;
          width: 250px;
        }
      }

      .header-left {
        display: flex;
        align-items: center;

        .menu-style {
          margin-left: 88px;
        }
      }

      .ant-menu-horizontal {
        line-height: 80px !important;
        font-size: 16px;
        border-bottom: 1px solid transparent;
        background: transparent;
        color: #fff;

        .ant-menu-item-selected,
        .ant-menu-submenu-selected,
        .ant-menu-submenu-open,
        .ant-menu-item-open,
        .ant-menu-submenu-active .ant-menu-item-active,
        .ant-menu-submenu:hover,
        .ant-menu-item:hover {
          font-size: 18px;
          color: #fff;
          border-bottom: 2px solid #fff;
          font-weight: bold;
        }

        // .ant-sub-menu-active>.ant-menu-item-active {
        //   font-size: 18px;
        //   color: #fff;
        //   border-bottom: 2px solid #fff;
        //   font-weight: bold;
        // }
      }


      .ant-menu-item,
      .ant-menu-submenu-title {
        padding: 0 42px;
      }

      .ant-input {
        height: 47px;
        font-size: 17px;
      }

      .ant-input-affix-wrapper {
        font-size: 23px;
      }
    }

    .scrollTopFixed {
      position: fixed;
      top: 0;
      left: 0;
      background-color: rgba(255, 255, 255, .3);
      backdrop-filter: blur(20px);
      z-index: 9;
      box-shadow: 0px 1px 3px #fff;
      color: #000;

      .ant-menu-horizontal {
        line-height: 80px !important;
        font-size: 16px;
        border-bottom: 1px solid transparent;
        background: transparent;
        color: #000;

        .ant-menu-item-selected,
        .ant-menu-submenu-selected,
        .ant-menu-submenu-open,
        .ant-menu-item-open,
        .ant-menu-submenu-active,
        .ant-menu-item-active,
        .ant-menu-submenu:hover,
        .ant-menu-item:hover {
          font-size: 18px;
          color: #FDAE34;
          border-bottom: 2px solid #FDAE34;
          font-weight: bold;
        }
      }

      .logoBox > .logo-name {
        font-size: 16px;
        font-weight: 500;
        color: #000;
        margin-left: 12px;
      }

    }
  }

  .topBox {
    width: 100%;
    min-height: 100vh;
    background-image: url('https://oss.itcait.com/jincdev/webImg/banner.png');
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;

    .topBanner {
      width: 1200px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between
    }

    .jcdImg {
      width: 284px;
      height: 76px;
      margin-top: 27px;
    }

    .titleFont {
      color: rgba(255, 255, 255, 0.84);
      font-size: 50px;
      font-weight: 500;
      line-height: 65px;
      letter-spacing: 0px;
      text-align: right;
      text-transform: uppercase;
    }

    .position {
      //display: flex;
      //justify-content: end;
      cursor: pointer;
      position: relative;

      .aboutus {
        width: 220px;
        border-radius: 10px;
        /* 渐变 */
        background: linear-gradient(131.26deg, rgb(253, 169, 51) -13.511%, rgba(253, 232, 51, 0.93) 100%);
        color: rgb(255, 255, 255);
        font-size: 24px;
        font-weight: 700;
        letter-spacing: 0px;
        text-align: center;
        text-transform: uppercase;
        padding: 7px 0;
        margin-top: 77px;
        position: absolute;
        right: 0;
      }
    }
  }

  .classification {
    width: 100%;
    padding: 40px 0;
    background: linear-gradient(180.00deg, rgba(253, 169, 51, 1) 3.426%, rgba(253, 232, 51, 0.93) 260.944%);

    .item {
      width: 928px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .itemBox {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;

        .itemImg {
          width: 55px;
          height: 55px;
        }

        .itemName {
          color: rgb(255, 255, 255);
          font-size: 18px;
          font-weight: 700;
          letter-spacing: 0px;
          text-align: center;
          text-transform: uppercase;
          margin-top: 10px;
        }
      }
    }
  }

  .mainBusiness {
    width: 100%;
    background-image: url("https://oss.itcait.com/jincdev/webImg/banner2.png");
    background-size: cover;
    background-repeat: no-repeat;
    padding: 50px 0;
    position: relative;

    .mainBusinessContent {
      display: flex;
      align-items: end;
      justify-content: space-between;
    }

    .top {
      display: flex;
      align-items: center;
    }

    .consumerDebt {
      color: rgba(0, 0, 0, 0.8);
      font-size: 36px;
      font-weight: 700;
      letter-spacing: 0px;
      text-transform: uppercase;
      padding-top: 65px;
    }

    .three {
      color: rgb(255, 135, 38);
      font-size: 24px;
      font-weight: 400;
      line-height: 19px;
      letter-spacing: 0px;
      text-transform: uppercase;
      margin-top: 23px;
    }

    .sortImg {
      width: 443px;
      margin-top: 30px;
      position: absolute;
    }

    .itemSHBox {
      width: 464px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 100px;

      .itemSH {
        display: flex;
        flex-direction: column;
        align-items: center;

        .itemSHImg {
          width: 61px;
          height: 61px;
        }

        .itemSHName {
          color: rgb(253, 169, 51);
          font-size: 24px;
          font-weight: 400;
          letter-spacing: 0px;
          text-align: right;
          text-transform: uppercase;
          margin-top: 10px;
        }
      }
    }

    .bottom {
      margin-top: 50px;
      display: flex;
      align-items: center;

      .shu1 {
        background: rgb(253, 169, 51);
        width: 2px;
        height: 135px;
      }

      .bottom-right {
        margin-left: 20px;
      }

      .bottomFont {
        color: rgba(0, 0, 0, 0.77);
        font-size: 14px;
        font-weight: 400;
        line-height: 25px;
        letter-spacing: 0px;
        text-align: left;
      }

      .bottomComputation {
        font-size: 14px;
        font-weight: 400;
        line-height: 25px;
        letter-spacing: 0px;
        text-align: left;
      }

      .irr {
        margin-top: 22px;
        font-weight: bold;
      }
    }
  }

  .shu {
    width: 7px;
    height: 29px;
    background: rgb(253, 169, 51);
  }

  .topTitle {
    color: rgb(0, 0, 0);
    font-size: 36px;
    font-weight: 700;
    letter-spacing: 0px;
    text-align: right;
    text-transform: uppercase;
    margin-left: 14px;
  }

  .qrBox {

    .qrImg {
      width: 360px;
      height: 426px;
    }

    .rightBottomBox {
      display: flex;
      align-items: center;

      .rightBottom {
        display: flex;
        align-items: center;
        margin-top: 40px;

        .through {
          color: rgba(0, 0, 0, 0.8);
          font-size: 24px;
          font-weight: 700;
          letter-spacing: 0px;
          text-align: right;
          text-transform: uppercase;
        }

        .tglImg {
          width: 33px;
          height: 33px;
          margin: 0 10px;
        }

        .jsImg {
          width: 33px;
          height: 33px;
          margin-left: 10px;
        }
      }
    }
  }

  .advantage {
    padding: 50px 0 138px;
    background: #fff;

    .advantageBox {
      width: 1200px;
      margin: 0 auto;

      .advantageTop {
        display: flex;
        align-items: center;
      }

      .advantageItem {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .advantageItemBox {
          display: flex;
          flex-direction: column;
          align-items: center;

          .itemADImg {
            width: 200px;
            height: 200px;
          }

          .itemADName {
            color: rgba(0, 0, 0, 0.77);
            font-size: 24px;
            font-weight: 700;
            letter-spacing: 0px;
            text-align: right;
            text-transform: uppercase;
          }

          .itemADContent {
            color: rgba(0, 0, 0, 0.77);
            font-size: 20px;
            font-weight: 300;
            letter-spacing: 0px;
            text-align: right;
            text-transform: uppercase;
            margin-top: 10px;
          }
        }
      }
    }
  }

  .products {
    width: 100%;
    height: 709px;
    background-image: url("https://oss.itcait.com/jincdev/webImg/banner3.png");
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 34px;
    position: relative;

    .productsBox {
      width: 1200px;
      height: 709px;
      margin: 0 auto;

      .productsTop {
        display: flex;
        align-items: center;
      }

      .productsContent {
        width: 1209px;
        height: 637px;
        background-image: url("https://oss.itcait.com/jincdev/webImg/jx.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        position: absolute;
        top: 17%;
        box-shadow: 1px 1px 11px 1px #ccc;
        border-radius: 25px;

        .jk1Img {
          width: 263px;
          height: 557px;
          position: absolute;
          left: 27%;
          top: -8%;
        }

        .sxImg {
          width: 317px;
          height: 683px;
          position: absolute;
          left: 49%;
          top: -24%;
        }

        .jkImg {
          width: 263px;
          height: 557px;
          position: absolute;
          right: 3%;
          top: -8%;
        }

        .productsContent-left {
          display: flex;
          flex-direction: column;
          position: absolute;
          top: 26%;
          left: 3%;
        }

        .jcdImg {
          width: 248px;
          height: 65px;
        }

        .xdImg {
          width: 243px;
          height: 52px;
          margin-top: 26px;
        }

        .jcdFont {
          width: 254px;
          color: rgba(0, 0, 0, 0.8);
          font-size: 14px;
          font-weight: 400;
          line-height: 25px;
          letter-spacing: 0px;
          text-align: left;
          margin-top: 30px;
        }
      }
    }
  }

  .aboutUs {
    width: 1200px;
    padding: 110px 0 70px;
    margin: 0 auto;

    .aboutUsTop {
      display: flex;
      align-items: center;
    }

    .recommendationOne {
      color: rgba(0, 0, 0, 0.8);
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0px;
      text-align: justify;
      text-transform: uppercase;
      margin-top: 25px;
    }

    .recommendationTwo {
      margin-top: 30px;
    }
  }

  .honor {
    width: 100%;
    height: 736px;
    background-image: url("https://oss.itcait.com/jincdev/webImg/banner4.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: relative;

    .honorBox {
      width: 1200px;
      margin: 0 auto;
    }
  }

  .xkzBox {
    position: absolute;
    top: 17%;
    right: 17%;

    .xkzImg {
      width: 491px;
      height: 354px;
    }
  }

  .recommendation {
    display: flex;
    align-items: center;
    padding-top: 80px;
  }

  .yuan {
    background: rgb(253, 192, 56);
    width: 12px;
    height: 12px;
    border-radius: 50%;
  }

  .recommendationName {
    color: rgba(0, 0, 0, 0.8);
    font-size: 24px;
    font-weight: 700;
    letter-spacing: 0px;
    text-align: justify;
    text-transform: uppercase;
    margin-left: 20px;
  }

  .recommendations {
    display: flex;
    align-items: center;
    padding-top: 26px;
  }

  .foot {
    width: 100%;
    padding: 70px 0 20px;
    position: relative;

    .footBox {
      width: 1200px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .title {
        color: rgba(0, 0, 0, 0.8);
        font-size: 18px;
        font-weight: 700;
        letter-spacing: 0px;
        text-align: justify;
        text-transform: uppercase;
      }

      .itemTitName {
        color: rgba(0, 0, 0, 0.8);
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 0px;
        text-align: left;
        text-transform: uppercase;
        margin-top: 14px;
        cursor: pointer;
      }

      .shu3 {
        border: 1px solid rgb(0, 0, 0);
        opacity: 0.14;
        width: 1px;
        height: 214px;
      }

      .phoneBox {
        display: flex;
        align-items: center;

        .phoneImg {
          width: 50px;
          height: 50px;
        }

        .phoneContent {
          margin-left: 58px;
          color: rgba(0, 0, 0, 0.8);
          font-size: 18px;
          font-weight: 400;
          letter-spacing: 1px;
          text-align: left;
          display: flex;
          align-items: center;

          .number {
            margin-left: 60px;
          }
        }

        .kfContent {
        }
      }

      .wx-qr {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .qrcode {
        width: 180px;
        height: 168px;
      }

      .wx {
        color: rgba(0, 0, 0, 0.8);
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 0px;
        text-align: left;
        text-transform: uppercase;
        margin-top: 16px;
      }
    }
  }

  .jtBox {
    position: absolute;
    top: -10%;
    left: 0;
  }

  .jtImg {
    width: 535px;
    height: 94px;
  }

  .address {
    width: 100%;
    padding: 40px 0 34px;
    background: rgba(0, 0, 0, 0.8);

    .bottom-address {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .bottom-name {
      color: #8a8a8c;
      font-size: 14px;
      font-weight: 700;
      letter-spacing: 0px;
      text-transform: uppercase;
      margin: 10px 0 0 20px;
      text-align: center;
    }

    .addressImg {
      width: 26px;
      height: 24px;
    }

    .addressName {
      color: rgb(255, 255, 255);
      font-size: 18px;
      font-weight: 700;
      letter-spacing: 0px;
      text-align: right;
      text-transform: uppercase;
      margin-left: 20px;
    }
  }

  .float {
    position: fixed;
    top: 40%;
    right: 7%;
    text-align: right;

    .navbarImg {
      width: 24px;
      height: 24px;
      cursor: pointer;
    }

    .itemNavName {
      margin-top: 14px;
      color: rgba(0, 0, 0, 0.8);
      font-size: 18px;
      font-weight: 400;
      letter-spacing: 0px;
      text-transform: uppercase;
      cursor: pointer;

      &:hover {
        color: #FDAE34;
      }
    }
  }
}
</style>
